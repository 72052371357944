import React from "react";
import { Handle } from "react-flow-renderer";

const RoadmapNode = ({ id, data }) => {
  const commonStyle = {
    padding: "20px 25px", 
    borderRadius: "5px",
    position: "relative",
    color: "white",
    width: "320px", 
    height: "150px", 
    textAlign: "center",
  };
  

  const titleStyle = {
    width: "220px",
    margin: "0 auto",
    textAlign: "center",
  };

  return (
    <div style={commonStyle}>
      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          borderColor: "transparent",
          width: 0,
        }}
      />
      <div style={titleStyle} className="roadmap-title">
        {data.title}
      </div>
      <div className="roadmap-description">
        {data.description}
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          background: "transparent",
          borderColor: "transparent",
          width: 0,
        }}
      />
    </div>
  );
};

export default RoadmapNode;
