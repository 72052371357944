import React from 'react'
import brand from '../Assets/Logo Big.png'
import line from '../Assets/line.png'
const Footer = () => {
  return (
    <>
        <div className="footer">
            <div className="footersection">
                <img className="fsec1" src={brand} alt="" />
                <img className='fsec3' src={line} alt="" />
                <div className="fsec2">
                    <div className="footerbox">
                        <h1 className="fboxhead">$HEARTAI</h1>
                        <div className="fboxlinks">
                            <a href='https://www.dextools.io/app/en/bnb/pair-explorer/0xc11892857d5f5ffba1c90ab6b7d37bf06eeaf0d1
' className="fboxlink">DexTools</a>
                            <a href='https://bscscan.com/address/0x5abacd3816295a27fe0530a717eb0f5bf21ed7b3' className="fboxlink">Bscscan</a>
                            <a href='https://pancakeswap.finance/swap?outputCurrency=0x5ABaCD3816295A27FE0530A717Eb0F5bF21ED7B3
' className="fboxlink">Pancakeswap </a>
                        </div>
                    </div>
                    <div className="footerbox">
                        <h1 className="fboxhead">Socials</h1>
                        <div className="fboxlinks">
                            <a href='https://t.me/HeartAIOfficial' className="fboxlink">Telegram</a>
                            <a href='https://twitter.com/HeartAItoken' className="fboxlink">Twitter</a>
                        </div>
                    </div>
                    <div className="footerbox">
                        <h1 className="fboxhead">Resources</h1>
                        <div className="fboxlinks">
                            <a href='/' className="fboxlink">Docs</a>
                            <a href='/' className="fboxlink">CoinMarketcap</a>
                        </div>
                    </div>
                </div>
            </div>
            <p className='copyright'>Please note that our services are not available to users in the United States or individuals in countries subject to embargo restrictions.</p>
        </div>
    </>
  )
}

export default Footer
