import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from '../Assets/Logo Big.png'

const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  return (
    <>
      <nav className="main-nav">
        <div className="navlogomain">
          <img src={logo} alt="" />
        </div>

        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
          <ul>
            <a href="https://t.me/HeartAIOfficial"><li>
              Telegram
            </li></a>
            <a href="https://twitter.com/HeartAItoken"><li>
              Twitter
            </li></a>
            <a href="/"><li>
              Docs
            </li></a>
            <a href="https://bscscan.com/address/0x5abacd3816295a27fe0530a717eb0f5bf21ed7b3 "><li>
              $HEARTAI
            </li></a>
              <li className="trymini">
                Try Heart AI
              </li>
          </ul>
        </div>
        <div className="social-media">
          <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
