import React from 'react'
import Accordian from './Accordian'

const Faq = () => {
  return (
    <div className="faq">
                <div className="faqcontainer">
                    <h1 className="faqheading">Frequently Asked Questions</h1>
                    <Accordian/>
                </div>
            </div>
  )
}

export default Faq
